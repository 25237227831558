import React from "react";
import styles from "./PullToRefresh.module.css";

interface PullToRefreshProps {
  refreshing: boolean;
  pullDistance: number;
  pullThreshold: number;
  children?: React.ReactNode;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({
  refreshing,
  pullDistance,
  pullThreshold,
  children,
}) => {
  // Функция для рендеринга iOS-стиля индикатора с палочками
  const renderIOSRefreshIndicator = () => {
    return (
      <div
        className={`${styles.iosRefreshIndicator} ${
          refreshing ? styles.spinning : ""
        }`}
      >
        {Array.from({ length: 12 }).map((_, i) => (
          <div
            key={i}
            className={`${styles.bar} ${refreshing ? styles.spinning : ""}`}
            style={{
              transform: `rotate(${i * 30}deg)`,
              opacity: refreshing
                ? undefined // В режиме refreshing используем CSS анимацию
                : Math.min(0.25 + (pullDistance / pullThreshold) * 0.75, 1) *
                  Math.max(0.3, 1 - (i / 12) * 0.7), // Градиент прозрачности для эффекта вращения
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      className={styles.pullToRefresh}
      style={{
        height: refreshing ? "60px" : `${Math.min(pullDistance, 60)}px`,
        opacity: refreshing
          ? 1
          : Math.min(pullDistance / (pullThreshold * 0.7), 1),
        marginBottom: pullDistance > 5 ? "10px" : "0px",
      }}
    >
      {children || renderIOSRefreshIndicator()}
    </div>
  );
};

export default PullToRefresh;
