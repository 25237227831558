// components/icons/IconBase.tsx
import React from "react";

export interface IconBaseProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  fill?: string;
  className?: string;
  onClick?: () => void;
  viewBox?: string; // Add viewBox as a prop
}

const IconBase: React.FC<IconBaseProps & React.SVGProps<SVGSVGElement>> = ({
  children,
  width,
  height,
  color = "currentColor",
  fill = "none",
  className,
  viewBox,
  ...props
}) => {
  // Generate viewBox if not provided
  const finalViewBox = viewBox || `0 0 ${width} ${height}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox={finalViewBox}
      className={className}
      color={color}
      {...props}
    >
      {children}
    </svg>
  );
};

export default IconBase;
