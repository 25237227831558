import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import testImage1 from "../../../assets/300x300.jpg";
// Import additional test images
import testImage2 from "../../../assets/test2.webp";
import testImage3 from "../../../assets/test3.webp";
import testImage4 from "../../../assets/test4.webp";
//import { formatRelativeTime } from "../../utils/dateFormatter";
import { Item } from "../../../types";
import styles from "./ItemCard.module.css";

// Use Item interface from types as a base for props
// Delete tg_id and user_id from the interface
type ItemCardProps = Omit<Item, "user_id" | "status">;

export const ItemCard: FC<ItemCardProps> = ({
  id,
  title,
  description,
  category,
  // created_at,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // Function to select image based on item id
  const getTestImage = (itemId: number | string): string => {
    // Convert string id to number and get remainder when divided by 4
    const imageIndex =
      typeof itemId === "string" ? parseInt(itemId) % 4 : itemId % 4;

    // Return one of test images based on remainder
    switch (imageIndex) {
      case 0:
        return testImage1;
      case 1:
        return testImage2;
      case 2:
        return testImage3;
      case 3:
        return testImage4;
      default:
        return testImage1;
    }
  };

  const handleClick = () => {
    // Pass current path as referrer and search parameters
    navigate(`/item/${id}`, {
      state: {
        referrer: location.pathname,
        referrerSearch: location.search,
      },
    });
  };

  return (
    <div className={styles.itemCard} onClick={handleClick}>
      <div className={styles.itemImg}>
        <img src={getTestImage(id)} alt={title} />
      </div>
      <div className={styles.itemContent}>
        <a className={styles.itemTitle}>{title}</a>
        <a className={styles.itemCategory}>{category}</a>
        <a className={styles.itemDesc}>{description}</a>
        {/*<a className={styles.itemTime}>{formatRelativeTime(created_at)}</a>*/}
      </div>
    </div>
  );
};

export default ItemCard;
