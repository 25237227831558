import { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./styles/App.css";
//import { TonConnectUIProvider } from "@tonconnect/ui-react";
import Navbar from "./components/layout/Navbar";
import MainPage from "./pages/MainPage";
import { useTelegramSDK } from "./hooks/useTelegramSDK";
import { useAuth } from "./hooks/useAuth";
// Ленивая загрузка компонентов
const YourItems = lazy(() => import("./pages/YourItems"));
const Settings = lazy(() => import("./pages/Settings"));
const ItemPage = lazy(() => import("./pages/ItemPage"));
const AddItemPage = lazy(() => import("./pages/AddItemPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const PaymentMethods = lazy(
  () => import("./pages/Settings/PaymentMethods/PaymentMethods")
);
const MyProfile = lazy(() => import("./pages/Settings/MyProfile/MyProfile"));
const ReviewsPage = lazy(() => import("./pages/ReviewsPage/ReviewsPage"));
// Компонент загрузки
const LoadingComponent = () => (
  <div className="page-loading">Загрузка страницы...</div>
);

function AppContent() {
  const location = useLocation();
  // Используем хуки для работы с Telegram SDK и авторизацией
  const { isInitialized, userData, rawInitData, isMobile } = useTelegramSDK();
  const { isAuthorized, authorize } = useAuth();

  // Check if current path is ItemPage
  //const isItemPage = location.pathname.startsWith("/item/");
  const hideNavbarRoutes = [
    "/item/",
    "/user/",
    "/payment-methods",
    "/add-item",
    "/MyProfile",
    "/reviews",
  ];

  const shouldHideMenu = hideNavbarRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  useEffect(() => {
    if (shouldHideMenu) {
      console.log("Скрываем navbar");
      document.body.style.setProperty("--navbar-visible", "0");
    } else {
      console.log("Показываем navbar");
      document.body.style.setProperty("--navbar-visible", "1");
    }
  }, [shouldHideMenu]);

  // Эффект для авторизации при загрузке данных из Telegram
  useEffect(() => {
    if (isInitialized && rawInitData && !isAuthorized) {
      console.log("Начинаем авторизацию...");
      authorize(rawInitData)
        .then(() => console.log("Авторизация выполнена успешно"))
        .catch((error) => console.error("Ошибка авторизации:", error));
    }
  }, [isInitialized, rawInitData, isAuthorized, authorize]);

  // Показываем загрузку, пока SDK не инициализировано
  if (!isInitialized) {
    return <div className="loading">Загрузка приложения...</div>;
  }

  return (
    <div className="app-container">
      <div className="content">
        <Suspense fallback={<LoadingComponent />}>
          <Routes>
            <Route path="/" element={<MainPage isMobile={isMobile} />} />
            <Route path="/item/:item_id" element={<ItemPage />} />
            <Route
              path="/items"
              element={<YourItems user={userData} isMobile={isMobile} />}
            />
            <Route path="/user/:userid" element={<ProfilePage />} />
            <Route path="/add-item" element={<AddItemPage user={userData} />} />

            <Route
              path="/settings"
              element={<Settings userData={userData} />}
            />
            <Route path="/payment-methods" element={<PaymentMethods />} />
            <Route
              path="/MyProfile"
              element={<MyProfile userData={userData} isMobile={isMobile} />}
            />
            <Route path="/reviews" element={<ReviewsPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </div>
      {!shouldHideMenu && (
        <div className="footer">
          <Navbar />
        </div>
      )}
    </div>
  );
}

// Main App component that provides router context
function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
