import { useEffect, useState, useRef, useCallback } from "react";
import styles from "./MainPage.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
//import searchIcon from "../../assets/search_28.svg"; // Update path if needed
import categoryIcon from "../../assets/temp_category.png"; // Update path if needed
import { useItems } from "../../hooks/useItems";
import { ItemCard } from "../../components/ui/ItemCard/ItemCard";
import { ItemCardSkeleton } from "../../components/ui/ItemCard/ItemCardSkeleton";
import { useImageLoader } from "../../hooks/useImageLoader";
import { HeartIcon } from "../../components/icons";
import { usePullToRefresh } from "../../hooks/usePullToRefresh";
import PullToRefresh from "../../components/ui/PullToRefresh/PullToRefresh";
import { SearchBar } from "../../components/ui/SearchBar/SearchBar";
import CategorySwitcher from "../../components/ui/CategorySwitcher/CategorySwitcher";

interface MainPageProps {
  isMobile: boolean;
}

interface Category {
  id: number | string;
  name: string;
  icon?: string | React.ReactNode;
}

function MainPage({ isMobile }: MainPageProps) {
  useImageLoader();
  // Get state and functions for working with items via hook
  const {
    items,
    isLoading,
    error,
    hasMore,
    fetchItems,
    changeCategory,
    activeCategory,
    clearCache,
  } = useItems("All");

  const [categories, setCategories] = useState<Category[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);

  // Load categories
  const fetchCategories = async () => {
    //setCategories(["Music", "Art", "Coding", "Design", "Other"]);
    setCategories([
      {
        id: 1,
        name: "Music",
        icon: categoryIcon,
      },
      {
        id: 2,
        name: "Art",
        icon: categoryIcon,
      },
      {
        id: 3,
        name: "Coding",
        icon: categoryIcon,
      },
      {
        id: 4,
        name: "Design",
        icon: categoryIcon,
      },
      {
        id: 5,
        name: "Other",
        icon: categoryIcon,
      },
    ]);
  };

  const handleCategoryClick = (categoryName: string) => {
    const newCategory = categoryName === activeCategory ? "All" : categoryName;
    changeCategory(newCategory);
  };

  // Refresh function to be passed to usePullToRefresh
  const refreshItems = useCallback(async () => {
    console.log("Starting refresh process");

    try {
      // Clear cache to force database refresh
      console.log("Calling clearCache()");
      const cacheCleared = await clearCache();
      console.log("Cache cleared:", cacheCleared);

      // fetchItems is no longer needed here as clearCache triggers the useEffect in useItems
    } catch (err) {
      console.error("Refresh error:", err);
    }
  }, [clearCache]);

  // Use the pull-to-refresh hook
  const {
    refreshing,
    pullDistance,
    pullThreshold,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleRefresh,
  } = usePullToRefresh({
    pullThreshold: 80,
    onRefresh: refreshItems,
  });

  // Load initial data
  useEffect(() => {
    fetchCategories();
    // fetchItems() is called inside useItems hook
    console.log("Component mounted, isMobile:", isMobile);
  }, []);

  // Generate skeleton placeholders
  const renderSkeletons = () => {
    // Create an array of 6 skeletons or based on screen width
    return Array(6)
      .fill(0)
      .map((_, index) => <ItemCardSkeleton key={`skeleton-${index}`} />);
  };

  // Добавляем состояние для поискового запроса
  const [searchQuery, setSearchQuery] = useState("");

  // Обработчик изменения поискового запроса
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Обработчик отправки поискового запроса
  const handleSearch = (query: string) => {
    console.log("Searching for:", query);
    // Здесь будет логика для фильтрации элементов или отправки запроса на API
  };

  return (
    <div
      className={styles.mainPage}
      ref={scrollRef}
      onTouchStart={isMobile ? handleTouchStart : undefined}
      onTouchMove={isMobile ? handleTouchMove : undefined}
      onTouchEnd={isMobile ? handleTouchEnd : undefined}
    >
      {/* Use the PullToRefresh component instead of inline refresh indicator */}
      {isMobile && (pullDistance > 0 || refreshing) && (
        <PullToRefresh
          refreshing={refreshing}
          pullDistance={pullDistance}
          pullThreshold={pullThreshold}
        />
      )}

      <div className={styles.header}>
        <div className={styles.headerSearch}>
          <SearchBar
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            onSearch={handleSearch}
          />
          <div className={styles.headerActions}>
            {!isMobile && (
              <button
                className={styles.refreshButton}
                onClick={handleRefresh}
                disabled={refreshing}
                aria-label="Refresh"
              >
                <svg
                  className={`${styles.refreshIcon} ${
                    refreshing ? styles.spinning : ""
                  }`}
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                >
                  <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 9h7V2l-2.35 4.35z" />
                </svg>
              </button>
            )}
            <div className={styles.heartContainer}>
              <HeartIcon width="24" height="21" />
            </div>
          </div>
        </div>
        <CategorySwitcher
          categories={categories}
          activeCategory={activeCategory}
          onCategoryClick={handleCategoryClick}
          defaultIcon={categoryIcon}
        />
      </div>

      {/* Show skeletons if we're loading initial data */}
      {isLoading && items.length === 0 ? (
        <div className={styles.infiniteScrollComponent}>
          {renderSkeletons()}
        </div>
      ) : (
        <InfiniteScroll
          dataLength={items.length}
          next={() => fetchItems()}
          hasMore={hasMore}
          loader={
            <div className={styles.infiniteScrollComponent}>
              {renderSkeletons()}
            </div>
          }
          scrollableTarget="root"
          endMessage={
            <div className={styles.endPage}>
              <p className={styles.endMessage}>
                -----------------------------------
              </p>
            </div>
          }
          scrollThreshold={0.9}
          className={styles.infiniteScrollComponent}
        >
          {items.map((item) => (
            <ItemCard
              key={item.id}
              id={item.id}
              title={item.title}
              description={item.description}
              category={item.category}
              created_at={item.created_at}
            />
          ))}
        </InfiniteScroll>
      )}

      {error && <div className={styles.errorMessage}>{error}</div>}
      {isLoading && items.length === 0 && (
        <div className={styles.loadingMessage}>Загрузка товаров...</div>
      )}
    </div>
  );
}

export default MainPage;
