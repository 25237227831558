import { FC, ReactNode } from "react";
import styles from "./CategorySwitcher.module.css";

interface Category {
  id: string | number;
  name: string;
  icon?: string | ReactNode;
}

interface CategorySwitcherProps {
  categories: Category[];
  activeCategory: string;
  onCategoryClick: (category: string) => void;
  defaultIcon?: string;
}

/**
 * A horizontal scrollable list of categories
 */
const CategorySwitcher: FC<CategorySwitcherProps> = ({
  categories,
  activeCategory,
  onCategoryClick,
  defaultIcon,
}) => {
  return (
    <div className={styles.categorySwitcher}>
      {categories.map((category) => (
        <div
          key={category.id}
          className={`${styles.categoryItem} ${
            activeCategory === category.name ? styles.categoryItemActive : ""
          }`}
          onClick={() => onCategoryClick(category.name)}
        >
          {/* Проверяем тип иконки и рендерим соответственно */}
          {typeof category.icon === "string" ? (
            <img
              src={
                category.icon ||
                (typeof defaultIcon === "string" ? defaultIcon : undefined)
              }
              className={styles.categoryIcon}
              alt={category.name}
            />
          ) : (
            <div className={styles.categoryIcon}>
              {category.icon || defaultIcon}
            </div>
          )}
          <a className={styles.categoryName}>{category.name}</a>
        </div>
      ))}
    </div>
  );
};

export default CategorySwitcher;
