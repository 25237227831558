import { useState, useRef, useCallback } from "react";

interface PullToRefreshOptions {
  pullThreshold?: number;
  onRefresh: () => Promise<void>;
}

export const usePullToRefresh = ({
  pullThreshold = 100,
  onRefresh,
}: PullToRefreshOptions) => {
  const [refreshing, setRefreshing] = useState(false);
  const [pullDistance, setPullDistance] = useState(0);
  const startY = useRef(0);

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    // Only allow pull-to-refresh at the top of the page
    if (window.scrollY === 0) {
      startY.current = e.touches[0].clientY;
    }
  }, []);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (startY.current === 0 || refreshing) return;

      const currentY = e.touches[0].clientY;
      const diff = currentY - startY.current;

      // Only allow pulling down, not up
      if (diff > 0 && window.scrollY === 0) {
        // Используем линейную функцию для более предсказуемого отклика
        const resistanceFactor = 3; // Увеличенное сопротивление для более плавного движения
        const newPullDistance = Math.min(
          diff / resistanceFactor,
          pullThreshold * 1.2
        );

        setPullDistance(newPullDistance);

        // Prevent default scrolling behavior
        e.preventDefault();
      }
    },
    [pullThreshold, refreshing]
  );

  const handleTouchEnd = useCallback(() => {
    if (pullDistance > pullThreshold && !refreshing) {
      handleRefresh();
    } else {
      setPullDistance(0); // Reset if not enough to trigger refresh
    }
    startY.current = 0;
  }, [pullDistance, pullThreshold, refreshing]);

  const handleRefresh = useCallback(async () => {
    if (refreshing) return;

    setRefreshing(true);

    try {
      await onRefresh();
    } catch (err) {
      console.error("Refresh error:", err);
    } finally {
      // Add a small delay for better UX
      setTimeout(() => {
        setRefreshing(false);
        setPullDistance(0); // Reset pull distance
      }, 800);
    }
  }, [refreshing, onRefresh]);

  return {
    refreshing,
    pullDistance,
    pullThreshold,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleRefresh,
  };
};
