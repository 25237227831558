import React from "react";
import { SearchIcon } from "../../icons";
import styles from "./SearchBar.module.css";

interface SearchBarProps {
  placeholder?: string;
  onSearch?: (query: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  className?: string;
}

/**
 * Reusable search bar component
 */
export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search",
  onSearch,
  onChange,
  value,
  className = "",
}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (onSearch && value) {
      onSearch(value);
    }
  };

  return (
    <form
      className={`${styles.searchForm} ${className}`}
      onSubmit={handleSubmit}
    >
      <div className={styles.searchContainer}>
        <div className={styles.searchIcon}>
          <SearchIcon width="16" height="16" viewBox="0 0 22 22" />
        </div>
        <input
          type="search"
          placeholder={placeholder}
          className={styles.searchInput}
          value={value}
          onChange={onChange}
        />
      </div>
    </form>
  );
};

export default SearchBar;
